<template>
  <CHeader with-subheader>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/">
<!--      <CIcon name="logo" height="48" alt="Logo"/>-->
      <img src="@/assets/logo.png" height="40"/>
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <img src="@/assets/logo.png" height="40"/>

<!--      <CHeaderNavItem class="px-3">-->
<!--        <CHeaderNavLink to="/dashboard">-->
<!--          Dashboard-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
<!--      <CHeaderNavItem class="px-3">-->
<!--        <CHeaderNavLink to="/users" exact>-->
<!--          Users-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
<!--      <CHeaderNavItem class="px-3">-->
<!--        <CHeaderNavLink>-->
<!--          Settings-->
<!--        </CHeaderNavLink>-->
<!--      </CHeaderNavItem>-->
    </CHeaderNav>
    <CHeaderNav>
<!--      <CHeaderNavItem class="px-3 c-d-legacy-none">-->
<!--        <button-->
<!--          @click="() => $store.commit('toggle', 'darkMode')"-->
<!--          class="c-header-nav-btn"-->
<!--        >-->
<!--          <CIcon v-if="$store.state.darkMode" name="cil-sun"/>-->
<!--          <CIcon v-else name="cil-moon"/>-->
<!--        </button>-->
<!--      </CHeaderNavItem>-->
<!--      <TheHeaderDropdownNotif/>-->
<!--      <TheHeaderDropdownTasks/>-->
<!--      <TheHeaderDropdownMssgs/>-->
<!--      <TheHeaderDropdownAccnt/>-->
<!--      <CHeaderNavItem class="px-3">-->
<!--        <button-->
<!--          class="c-header-nav-btn"-->
<!--          @click="$store.commit('toggle', 'asideShow')"-->
<!--        >-->
<!--          <CIcon size="lg" name="cil-applications-settings" class="mr-2"/>-->
<!--        </button>-->
<!--      </CHeaderNavItem>-->
      <div v-if="this.$store.getters['gateway/objProfile'].metamask.length == 0" class="text-center mr-4">
        <connect-wallet :header="true"  />
      </div>
<!--      <metamask />-->
      <CHeaderNavItem class="px-3" v-if="this.$store.getters['gateway/objProfile'].metamask.length > 0">
        <CButton  color="success" class="pr-4 pl-4 text-white" shape="pill" variant="outline">{{this.$store.getters['metamask/objBalance']}} BNB</CButton>
      </CHeaderNavItem>

    </CHeaderNav>

<!--    <CSubheader class="px-3">-->
<!--      <CBreadcrumbRouter class="border-0 mb-0"/>-->
<!--    </CSubheader>-->
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownNotif from './TheHeaderDropdownNotif'
import TheHeaderDropdownTasks from './TheHeaderDropdownTasks'
import TheHeaderDropdownMssgs from './TheHeaderDropdownMssgs'
import {mapGetters} from "vuex";

import ConnectWallet from "@/project/views/page/sub/ConnectWallet";
import Metamask from "@/project/Banks";

export default {
  name: 'TheHeader',
  components: {
    ConnectWallet,
    Metamask,
    TheHeaderDropdownAccnt,
    TheHeaderDropdownNotif,
    TheHeaderDropdownTasks,
    TheHeaderDropdownMssgs
  },

  data: function () {
    return {
      connect:false,
      // address: this.$store.getters['gateway/objProfile'].metamask
    }
  },
  mounted() {
    // this.$store.dispatch('metamask/onMetaMaskAddress')
  },
  created() {
    // this.onConnectMataMaskAddress();

  },
  methods: {
    onConnectMataMaskAddress(){
      // this.connect = true;
      // this.$store.dispatch('metamask/onMetaMaskAddress')
    },

    // onGetBalanceOf(){
    //   var obj = {};
    //   obj.contactAddress = address;
    //   obj.contact = "setTokenNEXT"
    //   this.$store.dispatch('metamask/getBalanceOf', obj)
    //
    //   var obj = {};
    //   obj.contactAddress = addressUSDT;
    //   obj.contact = "setTokenUSDT"
    //   this.$store.dispatch('metamask/getBalanceOf', obj)
    // }


  },

  computed:{
    ...mapGetters({
      profile:'gateway/objProfile'
      // address:'metamask/onGetMetaMaskAddress',
      // balance:'metamask/objBalance',
      // profile:'gateway/objProfile'
      // next:'metamask/objTokenNext',
      // usdt:'metamask/objTokenUSDT',

    })
  },

  watch: {
    // address(value){
    //   // this.$store.dispatch('metamask/onMetaMaskAddress')
    // },
    profile(value){
      // this.connect = true
      console.log(value)
      // this.$store.dispatch('metamask/onMetaMaskAddress')
    }

    // address(value){
    //   this.onGetBalanceOf();
    // },
    // next(value){
    //   console.log("next",value)
    // },
    // usdt(value){
    //   console.log("usdt",value)
    // }
  },

}
</script>
