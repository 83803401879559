<template>
  <CFooter :fixed="false" class="h-auto">
    <div class="w-100">
      <CRow class="mt-2">
        <CCol class="text-success">NEXTART Originalize your masterpiece</CCol>
        <CCol class="text-right">
<!--          <CButtonGroup style="left: 5px; top:5px" class="pr-1 pl-1 text-white position-absolute" >-->
            <CButton color="facebook"  shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 35px; height: 35px" >
              <CIcon name="cib-facebook-f" class="mr-1 ml-1"/>
            </CButton>
            <CButton color="twitter"  shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 35px; height: 35px" >
              <CIcon name="cib-twitter" class="mr-1 ml-1"/>
            </CButton>
            <CButton color="yahoo"  shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 35px; height: 35px" >
              <CIcon name="cib-discord" class="mr-1 ml-1"/>
            </CButton>
<!--          <CButton color="youtube" shape="pill" class="pr-1 pl-1 text-white mr-2" style="width: 40px; height: 40px" >-->
<!--            <CIcon name="cib-youtube"  class="mr-1 ml-1"/>-->
<!--          </CButton>-->
<!--          </CButtonGroup>-->
        </CCol>
        <CCol class="text-right mt-2 mb-2" col="12">
          <div class="mfs-auto">
            <span class="mr-1" target="_blank">Powered by</span>
            <a href="https://nextart.app" class="text-success">© 2021, NEXTART </a>
          </div>
        </CCol>
      </CRow>
<!--      <CRow>-->
<!--        <CCol class="text-right">-->
<!--          <div class="mfs-auto">-->
<!--            <span class="mr-1" target="_blank">Powered by</span>-->
<!--            <a href="https://nextart.app">© 2021, NEXTART </a>-->
<!--          </div>-->
<!--        </CCol>-->
<!--      </CRow>-->
    </div>

<!--    <div>-->
<!--      <a href="https://coreui.io" target="_blank">CoreUI</a>-->
<!--      <span class="ml-1">&copy; {{new Date().getFullYear()}} creativeLabs.</span>-->
<!--    </div>-->
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
